<template>
  <div class="register">
    <Header />
    <b-container class="register-container">
      <b-row class="justify-content-center w-100 m-0">
        <div>
          <b-card-group deck class="w-100 m-0">
            <b-card class="w-100 m-0">
              <div class="row w-100 m-0">
                <div class="col-lg-5 col-md-6 my-auto">
                  <div class="register-form">
                    <h1>Register</h1>
                    <template>
                      <div>
                        <form
                          action=""
                          method="POST"
                          @submit.prevent="handleSubmit"
                        >
                          <h3 class="login-label">User ID iTrade</h3>
                          <vs-input
                            id="username"
                            type="text"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            v-model="username"
                            required
                            @keyup="handleInputOnKeyup"
                          />

                          <!-- Password Message -->
                          <div class="error my-2" v-if="!$v.username.required">
                            <p>User-ID wajib dimasukan.</p>
                          </div>

                          <div
                            class="error my-2"
                            v-else-if="
                              !$v.username.minLength || !$v.username.maxLength
                            "
                          >
                            <p>
                              Panjang User-ID minimal adalah
                              {{ $v.username.$params.minLength.min }} dan
                              maksimal {{ $v.username.$params.maxLength.max }}
                            </p>
                          </div>

                          <h3 class="login-label">Password</h3>
                          <div class="my-2">
                            <small
                              ><i
                                >*password minimal 8 karakter, mengandung
                                minimal 1 huruf kapital dan 1 angka</i
                              >
                            </small>
                          </div>

                          <div class="row">
                            <div class="col-9">
                              <vs-input
                                v-show="!showPass"
                                id="password"
                                type="password"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="password"
                                required
                              />
                              <vs-input
                                v-show="showPass"
                                id="password"
                                type="text"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="password"
                                required
                              />
                            </div>
                            <div class="col-3">
                              <b-button
                                @click="showPass = !showPass"
                                block
                                class="btn-show-password py-2"
                              >
                                <div v-show="!showPass">
                                  <b-icon icon="eye"></b-icon>
                                </div>
                                <div v-show="showPass">
                                  <b-icon icon="eye-slash"></b-icon>
                                </div>
                              </b-button>
                            </div>
                          </div>

                          <!-- Password Message -->
                          <div class="error my-2" v-if="!$v.password.required">
                            <p>Password wajib dimasukan.</p>
                          </div>

                          <div
                            class="error my-2"
                            v-else-if="
                              !$v.password.minLength || !$v.password.maxLength
                            "
                          >
                            <p>
                              Panjang Password minimal adalah
                              {{ $v.password.$params.minLength.min }} dan
                              maksimal {{ $v.password.$params.maxLength.max }}
                            </p>
                          </div>

                          <h3 class="login-label">Konfirmasi Password</h3>

                          <div class="row">
                            <div class="col-9">
                              <vs-input
                                v-show="!showConfirmationPass"
                                id="password_confirmation"
                                type="password"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="password_confirmation"
                                required
                              />
                              <vs-input
                                v-show="showConfirmationPass"
                                id="password"
                                type="text"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="password_confirmation"
                                required
                              />
                            </div>
                            <div class="col-3">
                              <b-button
                                @click="
                                  showConfirmationPass = !showConfirmationPass
                                "
                                block
                                class="btn-show-password py-2"
                              >
                                <div v-show="!showConfirmationPass">
                                  <b-icon icon="eye"></b-icon>
                                </div>
                                <div v-show="showConfirmationPass">
                                  <b-icon icon="eye-slash"></b-icon>
                                </div>
                              </b-button>
                            </div>
                          </div>

                          <!-- Password Confirm Message -->
                          <div
                            class="error my-2"
                            v-if="!$v.password_confirmation.sameAsPassword"
                          >
                            <p>Password yang Anda masukkan tidak sama.</p>
                          </div>

                          <!-- Pin Section -->
                          <template v-if="showPin">
                            <h3 class="login-label">PIN</h3>
                            <div class="row">
                              <div class="col-9">
                                <vs-input
                                  id="pin"
                                  class="mb-2 mr-sm-2 mb-sm-0"
                                  v-model="pin"
                                  required
                                  :type="
                                    showConfirmationPin ? 'text' : 'password'
                                  "
                                />
                              </div>
                              <div class="col-3">
                                <b-button
                                  @click="
                                    showConfirmationPin = !showConfirmationPin
                                  "
                                  block
                                  class="btn-show-password py-2"
                                >
                                  <div v-show="!showConfirmationPin">
                                    <b-icon icon="eye"></b-icon>
                                  </div>
                                  <div v-show="showConfirmationPin">
                                    <b-icon icon="eye-slash"></b-icon>
                                  </div>
                                </b-button>
                              </div>
                            </div>

                            <!-- Pin Message -->
                            <div class="error my-2" v-if="!$v.pin.required">
                              <p>PIN wajib dimasukan.</p>
                            </div>

                            <div
                              class="error my-2"
                              v-else-if="!$v.pin.minLength || !$v.pin.maxLength"
                            >
                              <p>Panjang PIN harus 4</p>
                            </div>
                          </template>

                          <!-- Global Message -->
                          <div class="error my-2" v-if="globalErrorMessage">
                            <p>{{ globalErrorMessage }}</p>
                          </div>

                          <div class="mb-4"></div>
                          <div class="row">
                            <div
                              class="col-lg-12 col-md-6 d-flex flex-row-reverse"
                            >
                              <div class="mx-2 terms">
                                <div class="mx-2 ml-n1">
                                  <p>
                                    Saya telah membaca, memahami, dan menyetujui
                                    <b-link
                                      :href="`${frontendLink}/syarat-dan-ketentuan`"
                                      class="text-danger text-decoration-none"
                                      target="_blank"
                                      >Syarat dan Ketentuan.</b-link
                                    >
                                  </p>
                                </div>
                              </div>
                              <div class="align-items-center mt-1">
                                <vs-checkbox
                                  v-model="checked"
                                  color="secondary"
                                >
                                </vs-checkbox>
                              </div>
                            </div>
                          </div>
                          <div class="row justify-content-center">
                            <div class="col-lg-12 col-md-6">
                              <b-button
                                block
                                type="submit"
                                :disabled="
                                  !checked ||
                                  loading ||
                                  $v.$invalid ||
                                  disallowRegister
                                "
                                @click="handleSubmit()"
                                class="btn-tertiary py-2"
                              >
                                <div v-if="loading">
                                  <div
                                    class="spinner-border spinner-border-sm"
                                  ></div>
                                  Loading
                                </div>
                                <div v-else>Daftar</div>
                              </b-button>
                            </div>
                          </div>
                        </form>
                        <b-row
                          class="justify-content-md-center dont-have-account-row"
                        >
                          <div class="dont-have-account">
                            <span
                              >Belum mempunyai User ID ?
                              <b-link
                                class="login-link"
                                href="https://register.cgsi.co.id"
                                >Silakan Register Di Sini</b-link
                              >
                            </span>
                          </div>
                        </b-row>
                        <b-row class="justify-content-end text-center licensed">
                          <div class="col-lg-6 col-md-6">
                            <div class="license-text">
                              <span
                                >Berizin dan <br />
                                Diawasi oleh</span
                              >
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="ojk-logo text-center text-lg-left">
                              <img
                                class="ojk-logo-image"
                                src="@/assets/img/login/ojk-logo.webp"
                                alt=""
                              />
                            </div>
                          </div>
                        </b-row>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="col-lg-7 col-md-6">
                  <div class="register-img">
                    <img
                      src="@/assets/img/register/Frame 108944.png"
                      alt="register-img"
                      width="103%"
                    />
                  </div>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </b-row>
    </b-container>
    <Footer4 />
    <b-modal
      id="modal-failed-register"
      v-model="clientIdNotFound"
      size="md"
      class="modal-failed-register"
      centered
      hide-footer
    >
      <div class="d-block text-center">
        <img
          src="@/assets/img/ekyc/data-not-correct.svg"
          alt="data-not-correct"
        />
        <h1 class="header-modal p-4 my-2 text-dark">
          User ID CGS iTrade Fund itrade tidak ditemukan
        </h1>
        <p class="text-black">Silahkan lakukan registrasi terlebih dahulu</p>
        <b-link class="login-link my-2" href="https://register.cgsi.co.id/"
          >Silahkan Register disini</b-link
        >
        <!-- <p>{{errorMessage}}</p> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import Footer4 from "@/components/partials/footer/Footer4.vue";
import {
  sameAs,
  minLength,
  maxLength,
  required,
  numeric,
} from "vuelidate/lib/validators";
import axios from "axios";
import { encryptData } from "@/utils";

/**
 * Apa perbedaan dengan register biasa ? register page ini sudah direfactor dan sudah memiliki fitur
 * auto register tertentu yaitu detect fbonds register dan reksadana register.
 */

const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "RegisterFbonds",
  metaInfo: {
    title: "Registrasi | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer4,
  },
  data() {
    return {
      // Required Data
      frontendLink: process.env.VUE_APP_ROOT_FRONTEND,

      // Modal Form
      username: "",
      password: "",
      password_confirmation: "",
      pin: "",

      // Modal Form Optional
      s21ClientData: null,
      s21ClientEmail: null,

      // Conditional Rendering Main
      showPass: false,
      showConfirmationPass: false,
      showPin: false,
      showConfirmationPin: false,

      // Condition Rendering
      checked: false,
      clientIdNotFound: false,
      loading: false,
      globalErrorMessage: false,
      disallowRegister: false,
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
    }),
  },
  validations() {
    const based = {
      username: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(100),
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(100),
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
    };

    if (this.showPin) {
      return {
        ...based,
        pin: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(4),
          numeric,
        },
      };
    }

    return { ...based };
  },
  methods: {
    async handleInputOnKeyup() {
      try {
        this.username = this.username.toUpperCase();

        if (this.username.length > 6) {
          this.loading = true;
          let response = await instance({
            method: "GET",
            url: `${this.app_url}s21client?clientId=${encryptData(
              this.username
            )}`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });

          if (
            ["FI-INS", "FI-REG", "FI-CR", "FI-REB"].includes(
              response.data.data.ClientCategory
            )
          ) {
            this.showPin = true;
          }

          if (response.data.data.SalesPersonID.toUpperCase() == "FI01-3") {
            this.disallowRegister = true;
            this.globalErrorMessage = `Maaf anda tidak bisa registrasi untuk Obligasi, silahkan hubungi Sales Anda.`;
          }
        }
      } catch (err) {
        console.log(err);
        this.showPin = false;
        // alert("Terjadi Kesalahan");
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit() {
      if (!this.loading) {
        this.loading = true;

        try {
          let response = await instance({
            method: "GET",
            url: `${this.app_url}s21client?clientId=${encryptData(
              this.username
            )}`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });

          this.s21ClientData = response.data.data.ClientID;
          this.s21ClientEmail = response.data.data.Email;
          localStorage.setItem("client_email", this.s21ClientEmail);
          localStorage.setItem(
            "registration_temp",
            JSON.stringify(response.data.data)
          );
          localStorage.setItem("client_id", this.username);
          localStorage.setItem("checkUserId", this.username);
          localStorage.setItem("client_password", this.password);

          if (!this.s21ClientData) {
            throw new Error("Client ID Data Not Found");
          }
          let registerPost;
          // Khusus Register Fbonds
          if (this.showPin) {
            registerPost = await instance.post(
              `${this.app_url}fbonds/register`,
              {
                client_id: this.username,
                password: this.password,
                password_confirmation: this.password_confirmation,
                pin: this.pin,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
          } else {
            registerPost = await instance.post(
              `${this.app_url}auth/register`,
              {
                email: this.s21ClientEmail,
                name: this.s21ClientEmail,
                client_id: this.username,
                password: this.password,
                password_confirmation: this.password_confirmation,
                role: "3",
                type: "2",
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
          }

          localStorage.setItem(
            "access_token",
            `${registerPost.data.data.token_type} ${registerPost.data.data.access_token}`
          );
          
          this.globalErrorMessage = false;
          window.location.href = "/verification";
        } catch (error) {
          const errorBody =
            error?.response?.data?.data?.message ||
            error?.response?.data?.meta?.message ||
            error?.message ||
            "Terjadi Kesalahan saat melakukan request";
          this.globalErrorMessage = Array.isArray(errorBody)
            ? errorBody.join(", ")
            : errorBody;
          alert("Error");
          console.log(error);
          // this.clientIdNotFound = true;
          // this.loading = false;
          // // window.setTimeout(function () {
          // //   window.location.href = "https://register.CGS iTrade Fund.co.id";
          // // }, 3000);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  // watch: {
  //   username(value) {
  //     // ('FI-INS','FI-REG','FI-CR','FI-REB')
  //     const identitas = value.slice(0, 4);
  //     this.showPin = (identitas === "BFIL" || identitas === "RFIL" || identitas === "RFII") ? true : false
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.register-img {
  height: 100%;

  img {
  }
}

.register {
  background: #f3f4f6;
}

.register-container {
  padding-top: 140px;
  padding-bottom: 80px;
}

.con-vs-checkbox {
  justify-content: start;
  margin: 0;
}

.register-form {
  padding: 48px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #4b5563;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4f566b;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #111827;
}

a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #e60012;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vs-con-input-label {
  width: 100%;
}

.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #e53e3e;
  }
}

.login-label {
  padding-top: 16px;
}

.forgot-password-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #6b7280;
  text-decoration: none;
}

.remember-me {
  padding-top: 16px;
  padding-bottom: 16px;
}

.custom-control-label {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #3c4257;
}

.custom-control-label:before {
  background-color: #e60012 !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e60012 !important;
}

.card-body {
  padding: 0px;
  box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
    0px 4px 6px rgba(107, 114, 128, 0.05);
  border-radius: 5px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

.dont-have-account {
  padding-top: 28px;
}

.login-link {
  font-family: $inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #e60012;
  text-decoration: none;
}

.licensed {
  padding-top: 30px;
}

.license-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1f2937;
}

.ojk-logo-image {
  width: 120px;
}

.modal-register {
  z-index: 99999;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: black;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #4b5563;
  }
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 14px;
  }

  .login-container {
    padding-bottom: 60px;
  }

  .back-nav {
    padding: 0px 240px 20px 0px;
  }

  .card-body {
    width: 100%;
  }

  .register-img {
    display: none;
  }

  .forgot-password-link {
    font-size: 10px;
  }

  .dont-have-account-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .licensed {
    text-align: center;
  }

  .ojk-logo-image {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1366px) {
  .login-container {
    padding-bottom: 300px;
  }
}
</style>
